.terms {
    background-color: #000000; /* Match the background of other sections if needed */
    color: #f5f5f5; /* Match the text color of other sections */
    padding: 1em 0; /* Add padding for spacing */
  }
  
  .terms h1 {
    font-size: 1.5em;
  }
  
  .terms p {
    font-size: 1.25em;
  }
  
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  