.splash {
  color: #f5f5f5;
  background-color: #000000;
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
}

.section-divider {
  width: 100%;
  height: 2px;
  margin: 1rem 0;
}

.stats {
  margin-bottom: 0.5rem;
}

.stats p {
  margin: 0;
  font-size: 1.25em;
}

.underlinelink {
  font-size: 1.25em; 
  color: #ea6126;
  text-decoration: underline;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

/* Styles for the next arrow button */
.button-transparent {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}

/* Ensure the divider spans the full width of the container */
.section-divider {
  border: 0;
  border-top: 2px solid;
  margin: 0;
  width: 100% !important;
  height: 2px;
}
