nav {
  background-color: #000000;
  padding: 4px 4px;
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust z-index if necessary to ensure it's on top */
}

a {
  font-size: 1.25em;
}

.underlinelink {
  text-decoration: underline;
  color: #ffffff;
}
