/* Ensures the duckmint-section container takes up the full height of the viewport */
.duckmint-section {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure spacing between top and bottom content */
  align-items: center;
  position: relative; /* Position relative to allow absolute positioning inside */
}

/* Adjustments for the fence image */
.fence {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.underlinelink {
  font-size: 1.25em;
  color: #ea6126;
  text-decoration: underline;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

/* Styles for the next arrow button */
.button-transparent {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}
