@font-face {
  font-family: "NES";   /*Can be any text*/
  src: local("Pixel_NES"),
    url("./fonts/Pixel_NES.otf") format("opentype");
}

* {
  font-family: NES;
  image-rendering: pixelated !important;
}

.nes-radio {
  color: #ff650b !important;
}

a {
  display: inline-block;
     font-size: 1.25em; 
   color: #ff650b;
   text-decoration: none;
}

body {
  background-color: #3BC3FD;
  color: #ffffff;
}

.splash {
  background-color: #000000;
  color: #ffffff;
}

hr {
  border: 2px solid;
  border-radius: 0px;
  opacity: 100%;
  margin-top: -2px;
  width: 80vw!important;
}

p {
  font-size: 1em!important;
}

a.underlinelink {
  text-decoration: underline!important;
}

.splash {
  background-color: #000000;
  color: #ffffff;
}

.stats {
  font-size: 1.5em!important;
}

.progress {
  color: #000000 !important;
}

/* Apply transparent button style with white text */
.button-transparent {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2em;
  cursor: pointer;
  padding: 0.5em;
}

/* Rotate elements with the class 'rotate-90' by 90 degrees */
.rotate-90 {
  transform: rotate(90deg);
}

@media only screen and (max-width: 1152px) {
    /* Style adjustments for viewports that meet the condition */
.stats {
  font-size: .75em!important;
}
}