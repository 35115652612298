/* Ensures the ded-section container takes up the full height of the viewport */
.ded-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure spacing between top and bottom content */
  align-items: center;
  position: relative; /* Position relative to allow absolute positioning inside */
}

.shoot {
  display: none; /* Hide the extra fence */
}

.nes-progress {
  color: #000000 !important; 
}

/* Styles for the next arrow button */
.button-transparent {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}

/* Ensures the fence image is positioned at the bottom */
.fence {
  width: 100%;
  position: absolute;
  bottom: 0;
}

/* Position the hunter image at the bottom */
.hunter {
  position: absolute;
  bottom: 0em; /* Adjust this value to position the hunter correctly */
  left: 50%;
  transform: translateX(-50%);
}
