/* Ensures the zappmint-section container takes up the full height of the viewport */
.zappmint-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* Position relative to allow absolute positioning inside */
  background-image: url('./img/zapp.png'); /* Set the background image */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  padding: 1em; /* Add padding to avoid the edges */
}

.content-container {
  background-color: rgba(255, 255, 255, 0.6); /* Add a semi-transparent background */
  padding: 2em;
  border-radius: 1em;
  text-align: center;
  max-width: 500px;
  color: black;
  width: 100%;
}

p {
  font-size: 1.25em;
  margin-bottom: 1em;
}

.underlinelink {
  font-size: 1.25em;
  color: #ff650b;
  text-decoration: underline;
}

/* Styles for the next arrow button */
.button-transparent {
  margin-top: 1em;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}
