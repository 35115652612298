/* NotificationContext.css */
@font-face {
  font-family: "NES";   /*Can be any text*/
  src: local("Pixel_NES"),
    url("./fonts/Pixel_NES.otf") format("opentype");
}


.notification-container {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 1000;
}

.notification {
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: NES !important;
  font-size: 0.8em;
  text-align: center;
}

.notification.info {
  background-color: rgba(152, 152, 152, 0.851);
}

.notification.success {
  background-color: rgba(0, 228, 0, 0.77);
}

.notification.error {
  background-color: rgba(255, 0, 0, 0.772);
}

.notification.warning {
  background-color: rgba(255, 166, 0, 0.756);
}
