.faq {
  background-color: #97e500;
  color: #000000;
}

a {
  font-size: 1.25em; 
  color: #ea6126;
  text-decoration: none;
}

.underlinelink {
  font-size: 1.25em; 
  color: #ea6126;
  text-decoration: underline;
}

.stats {
  font-size: 1.5em !important;
}

@media only screen and (max-width: 1152px) {
  /* Style adjustments for viewports that meet the condition */
  .stats {
    font-size: 0.75em !important;
  }
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.5em;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

/* Styles for the next arrow button */
.button-transparent {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}
