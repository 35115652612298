/* Ensures the splash container takes up the full height of the viewport minus the nav height */
.splash {
  min-height: calc(100vh - 64px); /* Adjust 64px to the actual height of your nav bar */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* Allow absolute positioning within splash */
  overflow: hidden; /* Prevent overflow issues */
}

/* Center content vertically and horizontally within the container */
.splash .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Center the row within the container */
.splash .row {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Style the anchor elements */
a {
  font-size: 1.25em; 
  color: #ea6126;
  text-decoration: none;
}

/* Style for the step labels */
.step-label {
  display: block;
  margin-bottom: 1em;
}

/* Styling for the developed by section */
.developed-by {
  font-size: 0.8em;
  color: #ffffff;
  margin-top: 1em;
}

.developed-by span {
  display: block;
  margin-bottom: 0.5em;
}

/* Underline link styling */
.underlinelink {
  text-decoration: underline;
  color: #ffffff;
  margin-right: 0.5em;
}

/* Padding for the splash header */
.splash h2.pt-4, .splash h2.pb-4 {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Style for the arrow button */
.button-transparent {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff650b;
}
